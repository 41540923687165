import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function AboutUs() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">About Us</h2>
              <h5 className="description">
              We are a small team of problem solvers who believe that real life problems could be solved by technology. 
              When we traveled to different parts of the world we faced many challenges with food, accommodation, money 
              transfers, etc. We have now rolled up our sleeves to fix one problem at a time.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutUs;
