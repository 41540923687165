import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

// sections for this page
import Images from "./index-sections/Images.js";
import Carousel from "./index-sections/Carousel.js";
import Mission from "./index-sections/Mission.js";
import AboutUs from "./index-sections/AboutUs.js";
import AppScreens from "./index-sections/AppScreens.js";
import Download from "./index-sections/Download.js";
import {PageName} from "components/Common/Constants.js"

function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          <Images />
          <Carousel />
          <Mission />
          <AboutUs />
          <AppScreens Page={PageName.Home} />
          <Download />
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Index;
