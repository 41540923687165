import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Images() {
  return (
    <>
      <div className="section section-images" style={{backgroundColor: 'rgba(238,238,238,0.4)'}}>
        <Container>
          <Row>
          {/* <Col md="2"></Col> */}
            <Col md="12">
              <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-1-new.png").default}
                ></img>
              </div>
              <div className="hero-images-container-1">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-2.png").default}
                ></img>
              </div>
              <div className="hero-images-container-2">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-3.png").default}
                ></img>
              </div>
            </Col>
            {/* <Col md="2"></Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Images;
