export const PageName = Object.freeze({
    Home: 0,
    ContactUs : 1,
    Preview : 2,
    Terms : 3
  });
  
  export const PreviewType = Object.freeze({
    A: "A",
    K : "K",
    F : "F"
  });

  export const PreviewEndPoint = "l5j/Fk2WV2iXJa81yEgfLNe91ULrVNn3GHRJciZ2W8TL9Ca+aRrDlY4wjDf9hDK/own1QXn++mhrlO0m/VDRRR493zFR+4XaibdaNhd5FrA=";
  
  export const PreviewProdEndPoint = "tyc5ufvYvXy+jTTVgnef+XK99P1wxiKmTKwUlZMgaZyH29wlXASJ6HbnTQLjf5B/9MFl3idHQavAuG2J2I+afvq+1pAkNop3xp6kpKhVL9k=";
