import React from "react";
// reactstrap components
import { Container, Row } from "reactstrap";
import {PageName, PreviewType} from "components/Common/Constants.js"

// core components

function AppScreens({Page, PreviewTypeValue, IsDataAvailable}) {
  return (
    <>
    <div
        className={ Page === PageName.Preview ? "" : "section section-signup"} data-background-color={Page === PageName.Preview ? "black": ""}
        style={ Page === PageName.Home ? { 
          backgroundImage:
            "url(" + require("assets/img/bg11.jpg").default + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "700px",
        } : {}}
      >
        <div className={ Page !== PageName.Preview ? "space-50" : ""}></div>
        {
          Page === PageName.Preview && PreviewTypeValue === PreviewType.A ? (<h2 className={ IsDataAvailable ? "more-info-title" : "title" }>See more info in the App</h2>): (<></>)
        }
        {
          Page === PageName.Preview && PreviewTypeValue !== PreviewType.A ? (<h2 className={ IsDataAvailable ? "more-info-title" : "title" }>Order food or see more info in the App</h2>): (<></>)
        }
        <Container className="text-center">
          <Row>
            <div className="col">
              <a href="https://apps.apple.com/in/app/xpatso/id1592275631" target="_blank"  rel="noopener noreferrer">
                <img
                  alt="..."
                  className="img-raised img-app-screen"
                  src={require("assets/img/XpatsoAppleApp2.png").default}
                ></img>
              </a>
              <br/><br/>
              <a href="https://apps.apple.com/in/app/xpatso/id1592275631" target="_blank"  rel="noopener noreferrer">
                <img
                  alt="iOS App"
                  className="img-raised img-badge img-ios-badge-small"
                  src={require("assets/img/App_Store_Badge.svg").default}
                ></img>
              </a>
            </div>
            <div className="col">
              <a href="https://play.google.com/store/apps/details?id=com.justus.xpatso" target="_blank" rel="noreferrer">
                <img
                  alt="..."
                  className="img-raised img-app-screen"
                  src={require("assets/img/XpatsoAndroidApp.png").default}
                ></img>
              </a>
              <br/>
              <a href="https://play.google.com/store/apps/details?id=com.justus.xpatso" target="_blank" rel="noreferrer">
                <img
                  alt="Android App"
                  className="img-raised img-app-badge-small"
                  src={require("assets/img/google-play-badge.png").default}
                ></img>
              </a>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AppScreens;
