const crypto = require("crypto");

export const decrypt = (encryptedData,  AesKey, AesIV) => {

    const encryptionType = 'aes-256-cbc';
    const encryptionEncoding = 'base64';
    const bufferEncryption = 'utf-8';

    const buff = Buffer.from(encryptedData, encryptionEncoding);
    const key = Buffer.from(AesKey, bufferEncryption);
    const iv = Buffer.from(AesIV, bufferEncryption);
    const decipher = crypto.createDecipheriv(encryptionType, key, iv);
    const deciphered = decipher.update(buff) + decipher.final();

    return deciphered;
}
