/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default">
        <Container>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Designed by{" "}
            <a
              href="" rel="noreferrer"

            >
              Invision
            </a>{" & "}
            <a
            href="/index"
            target=""
          >
            XPATSO
          </a>
            . Coded by{" "}
            <a
              href="" rel="noreferrer"
            >
              Creative Tim
            </a>{" & "}
          <a
            href="/index"
            target=""
          >
            XPATSO
          </a>
          .
        </div>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
