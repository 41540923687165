import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// core components

function Download() {
  return (
    <>
      <div
        className="section section-download"
        data-background-color="black"
      >
        <Container>
        <Row className="text-center mt-5">
            <Col className="ml-auto mr-auto" md="8">
              <h2>Want more?</h2>
              <h5 className="description">
              We are all ears for new ideas and new problems to solve to ease your expat life.
              Send us more suggestions and ideas by mail or phone given below.
              </h5>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12" id="contact">
              <h3 className="title">Contact Us</h3>
              <h5 className="description">
                
              </h5>
            </Col>
            <Col className="text-center" lg="9" md="12">
            <Button
                className="btn-round"
                color="primary"
                href="tel:+91-9499058475" 
                target="_blank"
                outline
                role="button"
                size="lg"
              >
                <img
                  alt="india contact"
                  className="img-badge"
                  src={require("assets/img/flags/IN.png").default}
                ></img>{'  '}
                +91-9499058475
              </Button>{'  '}
              <Button
                className="btn-round mr-1"
                color="info"
                href="mailto: xpatsoapp@gmail.com"
                role="button"
                size="lg"
                target="_blank"
              >
                <i className="fa fa-envelope"></i> {'  '}
                xpatsoapp@gmail.com
              </Button>
              {/* <Button
                className="btn-round"
                color="primary"
                href="tel:+598-95425869" 
                target="_blank"
                outline
                role="button"
                size="lg"
              >
                <img
                  alt="Uruguay contact"
                  className="img-badge"
                  src={require("assets/img/flags/UY.png").default}
                ></img>{'  '}
                +598-95425869
              </Button> */}
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
          <Row className="justify-content-md-center sharing-area text-center">
            <Col className="text-center" lg="8" md="12">
              <h3>Thank you for supporting us!</h3>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                className="btn-neutral btn-icon btn-round"
                color="twitter"
                href="https://twitter.com/xpatsoapp"
                id="tooltip86114138"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-twitter"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip86114138">
                Follow us
              </UncontrolledTooltip>
              <Button
                className="btn-neutral btn-icon btn-round"
                color="facebook"
                href="https://www.facebook.com/Xpatso-104516085402015"
                id="tooltip735272548"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-facebook-square"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip735272548">
                Like us
              </UncontrolledTooltip>
              <Button
                className="btn-neutral btn-icon btn-round"
                color="instagram"
                href="https://instagram.com/xpatso"
                id="tooltip647117716"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip647117716">
                Follow us
              </UncontrolledTooltip>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Download;
