import React, { useState } from "react";
import { useLocation } from "react-router-dom";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { PageName } from "components/Common/Constants.js";
import AppScreens from "views/index-sections/AppScreens.js";
import { PreviewType } from "components/Common/Constants";
import { Helmet } from "react-helmet";
import { decrypt } from "components/Common/cryptography";
import { PreviewProdEndPoint } from "components/Common/Constants";

function Preview({ Type }) {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [Title, SetTitle] = useState("");
  const [TotalVacancy, SetTotalVacancy] = useState(0);
  const [AvailableVacancy, SetAvailableVacancy] = useState(0);
  const [ImageSrcList, SetImageSrcList] = useState([]);
  const [Details, SetDetails] = useState("");

  const fetchPreview = () => {
    var url = decrypt(
      PreviewProdEndPoint,
      "$C&F)J@NcRfUjXn2r5u8x/A%D*G-KaPd",
      "$B&E)H@McQfTjWnZ"
    ).replaceAll('"', "");

    return fetch(url + Type.toString() + "?id=" + id, { method: "GET" })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        SetImageSrcList(data.ImageUrl);

        if (Type === PreviewType.A) {
          SetTitle("Apartment - " + data.Name);
          SetAvailableVacancy(data.TotalPerson);
          SetTotalVacancy(data.NoOfVacancy);
        } else if (Type === PreviewType.F) {
          SetTitle("Food - " + data.Name);
          SetDetails(data.Details);
        } else if (Type === PreviewType.K) {
          SetTitle("Kitchen - " + data.Name);
        }
      })
      .catch((err) => {
        // some error handling
      });
  };

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    fetchPreview();

    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Xpatso | {Title}</title>
        <meta property="og:title" content={"Xpatso | " + Title} />
        <meta property="og:url" content={window.location.href} />

        <meta
          property="og:image"
          content={ImageSrcList.length > 0 ? ImageSrcList[0].url : ""}
        />

        <meta
          property="og:image:secure_url"
          content={ImageSrcList.length > 0 ? ImageSrcList[0].url : ""}
        />
      </Helmet>
      <OtherNavbar Page={PageName.Preview} />
      <div className="wrapper preview">
        <div className="section section-about-us" data-background-color="black">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                {Type === PreviewType.A && ImageSrcList.length > 0 ? (
                  <h2 className="title">{Title}</h2>
                ) : (
                  <></>
                )}

                {Type === PreviewType.K && ImageSrcList.length > 0 ? (
                  <h2 className="title">{Title}</h2>
                ) : (
                  <></>
                )}

                {Type === PreviewType.F && ImageSrcList.length > 0 ? (
                  <h2 className="title">{Title}</h2>
                ) : (
                  <></>
                )}
                {ImageSrcList.map((image, index) => {
                  return (
                    <img
                      alt={"images of " + Title}
                      key={"img-" + index}
                      className={
                        ImageSrcList.length > 2
                          ? "preview-image-array "
                          : "preview-image"
                      }
                      src={image.url}
                    />
                  );
                })}
                {Type === PreviewType.A && ImageSrcList.length > 0 ? (
                  <div>
                    <br />
                    <h3>Total tenants : {TotalVacancy}</h3>{" "}
                    <h3> Vacancy available : {AvailableVacancy}</h3>
                  </div>
                ) : (
                  <></>
                )}

                {Type === PreviewType.F && ImageSrcList.length > 0 ? (
                  <div>
                    <br />
                    <h3>{Details}</h3>
                  </div>
                ) : (
                  <></>
                )}
                <AppScreens Page={PageName.Preview} PreviewTypeValue={Type} IsDataAvailable={!!Title} />
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default Preview;
