import React, { useEffect, useState } from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// core components
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { PageName } from "components/Common/Constants.js";
import AppScreensDeleteAccount from "views/index-sections/AppScreensDeleteAccount.js";
import { Helmet } from "react-helmet";

function Preview({ Type }) {
  const [Title] = useState("Delete My Account");

  useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Xpatso | {Title}</title>
        <meta property="og:title" content={"Xpatso | " + Title} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <OtherNavbar Page={PageName.Preview} />

      <div className="wrapper delete">
        <div className="section section-about-us" data-background-color="black">
          <Container>
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                <h3>Deleting your account will:</h3>
                <ul>
                    <li>Delete your account from Xpatso.</li>
                    <li>Delete all the posts.</li>
                    <li>Delete all the kitchens, apartments, food items you have listed.</li>
                </ul>
                <br/>
                <h3>We would love to hear from you.</h3>
                <ul>
                    <li>Please contact us if you have any concerns or suggestions about improving our apps and services.</li>
                    <li>Please give us a chance to rectify any issues you find with our services before deleting the account permanently.</li>
                </ul>

                <div className="contact-us-button">
                  <Button href="../contact">Contact Us</Button>
                </div>
                <br/>
                <h5>If you have made up your mind, please confirm with the verification code in the App.</h5>
                <AppScreensDeleteAccount Page={PageName.Preview} PreviewTypeValue={Type} IsDataAvailable={!!Title} />
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default Preview;
