import React from "react";


// core components
import OtherNavbar from "components/Navbars/OtherNavbar.js"
import Download from "./Download";
import {PageName} from "components/Common/Constants.js"

function ContactUs() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <OtherNavbar Page={PageName.ContactUs}/>
      <div className="wrapper">
      <Download/>
      </div>
    </>
  );
}

export default ContactUs;
