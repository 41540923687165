import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import OtherNavbar from "components/Navbars/OtherNavbar.js"
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import {PageName} from "components/Common/Constants.js"

function Terms() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <OtherNavbar Page={PageName.Terms} />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Terms and Conditions of Use and Privacy Policies</h2>
                <div className="description">
                    The Summary
                    <br/><br/>
                    Hello. You should read these terms and conditions in full because they apply every time you visit xpatso and use xpatso products.
                    However, just in case you ever need a reminder of the main points, here's a quick summary:
                    <br/><br/>
                    We're not responsible for anything that you post or say while you are on xpatso and we don't monitor the 
                    content of the site and apps, but if we do see or someone alerts us that you have posted something that we 
                    think is inappropriate then we are allowed at our discretion to remove it.
                    <br/><br/>
                    If you post any content that actually belongs to someone else and they get annoyed (or even call in their lawyers), 
                    we are not in the firing line. You have to take responsibility for what you post.
                    <br/><br/>
                    If you are concerned about how your data is being used by xpatso then please refer to our Privacy Policy. 
                    It will tell you all you need to know.
                    <br/><br/>
                    The full legal bit
                    xpatso is a social networking and communication web and mobile application designed as a forum for meeting 
                    new people when you travel to new places around the world, a place to have discussions and to share photos, 
                    news and information. It is intended to be a fun place to visit and it is important to us (and for you) that 
                    it remains a safe and friendly environment so you agree that you will only use xpatso in a manner consistent 
                    with its purpose and which is in accordance with these terms and conditions (the "Terms"). 
                    The Terms constitute a binding legal agreement between you as user ("you") and Xpatso, ("we" or "us") of 
                    www.xpatso.com (together with affiliated websites and applications, "xpatso"). 
                    <br/>
                    <br/>
                    The Terms apply whenever you visit xpatso, whether or not you have chosen to register with us, so please 
                    read them carefully. By accessing, using, registering for or receiving services offered on xpatso 
                    (the "Services") you are accepting and agreeing to be bound by the Terms.
                    <br/><br/>
                    IF YOU DO NOT ACCEPT AND AGREE TO THE TERMS THEN YOU MUST NOT ACCESS OR
                    USE THE SITE OR APPLICATIONS.
                    <br/><br/>
                    1. Use of the site and rules relating to content
                    <br/><br/>
                    Who can use xpatso?
                    <br/><br/>
                    Xpatso is a meeting place for anyone who is new to a country, state, city. You may only use and become a registered 
                    member of Xpatso if you are 18 years old or older (or the age of majority in the country in which you reside if that 
                    happens to be greater than 18).
                    You warrant that you have the right, authority and capacity to enter into and be bound by the Terms and that by using 
                    Xpatso you will not be violating any law or regulation of the country in which you are resident. You are solely 
                    responsible for your compliance with all applicable local laws and regulations. You further warrant that you have not 
                    been convicted of, nor are subject to any court order relating to assault, violence, sexual misconduct or harassment.
                    <br/><br/>
                    What kind of content can I post on Xpatso?
                    <br/><br/>
                    You are able to post all kinds of things on Xpatso, including photographs, emails, messages and other content ("Content").
                    There are some rules about what is acceptable though, so when you are using Xpatso you may not post or send any Content 
                    which: contains expletives or language which could be deemed offensive or is likely to harass, upset, embarrass, alarm or 
                    annoy any other person; 
                    <br/>
                    is obscene, pornographic or otherwise may offend human dignity; 
                    <br/>
                    is abusive, insulting or threatening, or which promotes or encourages racism, sexism, hatred or bigotry; incorporates 
                    the image or likeness of any individual under 18 years of age; 
                    <br/>
                    encourages any illegal activity including, without limitation, terrorism, inciting racial hatred or the submission 
                    of which in itself constitutes committing a criminal offence; 
                    <br/>
                    is defamatory;
                    <br/>
                    relates to commercial activities (including, without limitation, sales, competitions and advertising, links to other 
                    websites or premium line telephone numbers);
                    <br/><br/>

                    involves the transmission of "junk" mail or "spam";
                    <br/><br/>
                    contains any spy ware, adware, viruses, corrupt files, worm programmes or other malicious code designed to interrupt,
                    damage or limit the functionality of or disrupt any software, hardware, telecommunications, networks, servers or other
                    equipment, Trojan horse or any other material designed to damage, interfere with, wrongly intercept or expropriate any
                    data or personal information whether from Xpatso or otherwise; or
                    itself, or the posting of which, infringes any third party's rights (including, without limitation, intellectual property rights
                    and privacy rights).
                    <br/><br/>
                    Please use your common sense when picking the Content that you choose to post on or send via Xpatso
                    because you are solely responsible for, and bear all liability in relation to, such Content.
                    <br/><br/>
                    Are there any rules relating to personal information like my email address?
                    <br/><br/>
                    Any personal contact or personal information, photos, texts displayed in Kitchen, Apartment and Order details whether in
                    relation to you or any other person (for example, names, home addresses or postcodes, telephone numbers, email addresses, 
                    Location and any such information) 
                    are at your own risk and Xpatso is not responsible for any trouble or consequences you may face. If you are not sure then 
                    you may not display any personal contact or banking information on your individual profile page
                    ("Profile") whether in relation to you or any other person (for example, names, home addresses or
                    postcodes, telephone numbers, email addresses, URLs, credit/debit card or other banking details). If you
                    do choose to reveal any personal information about yourself to other users, whether via email or
                    otherwise, it is at your own risk. We encourage you to use the same caution in disclosing details about
                    yourself to third parties online as you would under any other circumstances. We recommend you follow
                    our Guidelines and Safety Tips when doing so.
                    <br/><br/>
                    What about other people"s personal information, can I use it?
                    <br/><br/>
                    You may only use other Xpatso user's personal information to the extent that your use of it matches
                    Xpatso's purpose of allowing people to meet one another. You may not use other users' information for
                    commercial purposes, to spam, to harass, or to make unlawful threats. Xpatso reserves the right to
                    terminate your account if you misuse other users' information.
                    <br/><br/>
                    Some of my friends want to see all the people I'm chatting with. Can I tell them my password and let
                    them log on as me?
                    <br/><br/>
                    The short answer is no. Only you are authorized to access your account with Xpatso. You must not share
                    your password with anyone or let anyone else access your account as this jeopardises the security of all of
                    the Content and personal information that you have submitted to Xpatso. You are responsible for keeping
                    your password secret and secure. If you don't, Xpatso is not liable for any unauthorized access to your
                    account. If you suspect that someone has gained access to your account, you must let us know
                    immediately by visitingFeedback page and telling us of your suspicions or concerns. You must also
                    immediately change your password. Xpatso reserves the right to terminate your account if you violate our
                    rules on keeping your password secure.
                    <br/><br/>
                    Who can see the Content that I put on Xpatso?
                    <br/><br/>
                    When you upload Content to Xpatso it can be accessed and viewed by the general public. If you do not
                    want such Content to be viewed by others, then you can opt to use the private folders and select the
                    person to whom you wish to give access. If you still do not want such Content to be viewed by others,
                    then you should not upload it to Xpatso. We reserve the right (without obligation) at our sole discretion. 
                    This Term and Conditions will change often and you agree to check this often for the updates. In case of any 
                    legal issues you agree to contact us first and resolve by arbitration. The Photos or Location information is not 
                    shared with anyone unless you agree to share it by ordering a food or creating a kitchen or apartment profile. and 
                    you can change them any time. 
                    <br/><br/>
                    Is Xpatso<sup><small>TM</small></sup> a registered trademark?
                    <br/><br/>
                    Yes, it the registered trademark and brand of JUST US SOFTWARES AND TECHNOLOGIES PRIVATE LIMITED. 
                </div>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default Terms;
