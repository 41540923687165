/*

=========================================================
* Now UI Kit React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import Terms from "views/terms/Index.js";
import DeleteAccount from "views/deleteAccount/Index.js";
import Preview from "views/PreviewContent/Index.js";
import ContactUs from "views/index-sections/ContactUs";
import {PreviewType} from "components/Common/Constants.js"

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
         <Route
          path="/terms.html"
          render={(props) => <Terms {...props} />}
        />
        <Route
          path="/terms"
          render={(props) => <Terms {...props} />}
        />
        <Route
          path="/contact"
          render={(props) => <ContactUs/>}
        />
        <Route
          path="/support"
          render={(props) => <ContactUs/>}
        />
        <Route
          path="/A"
          render={(props) => <Preview Type={PreviewType.A}/>}
        />
        <Route
          path="/K"
          render={(props) => <Preview Type={PreviewType.K}/>}
        />
        <Route
          path="/F"
          render={(props) => <Preview Type={PreviewType.F}/>}
        />
        <Route
          path="/DeleteAccount"
          render={(props) => <DeleteAccount/>}
        />
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
