import React from "react";
// reactstrap components
import { Col, Container, Row } from "reactstrap";

// core components
function AppScreens() {
  return (
    <>
    <div className="app-screen-delete-account" data-background-color= "black">
        <div className="space-50"></div>
        <Container className="text-center">
          <Row>
            <div className="col">
              <Row>
                <Col>
                  <img
                    alt="iOS App"
                    className="img-raised img-app-screen"
                    src={require("assets/img/deleteAccountStep1.png").default}
                  ></img>
                </Col>
                <Col>
                <div className="vertical-center">
                  <p>Select "Account" option in the Settings page.</p>
                </div>
                </Col>
              </Row>
              <br/><br/>

              <Row>
                <Col>
                  <img
                    alt="iOS App"
                    className="img-raised img-app-screen"
                    src={require("assets/img/deleteAccountStep2.png").default}
                  ></img>
                </Col>
                <Col>
                  <div className="vertical-center">
                    <p>Select "Delete My Account" option in the Account page.</p>
                  </div>
                </Col>
              </Row>
                <br/><br/>
                <Row>
                  <Col>
                    <img
                      alt="iOS App"
                      className="img-raised img-app-screen"
                      src={require("assets/img/deleteAccountStep3.png").default}
                    ></img>
                  </Col>
                  <Col>
                    <div className="vertical-center">
                      <ol>
                        <li>Enter your registered email id and click on the "Send Code" button.</li>
                        <li>You should receive a verification code sent to your mail id.</li>
                        <li>Enter that verification code and click "Delete Account" button.</li>
                      </ol>
                    </div>
                </Col>
                </Row>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AppScreens;
